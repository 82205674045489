import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Better Looking Bullet Placeholders",
  "date": "2014-08-07T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A common pattern these days, is to put bullets as a field’s placeholder to indicate a credit card number, a discount or a password field. The problem is that, if you just put `}<inlineCode parentName="p">{`•••• •••• •••• ••••`}</inlineCode>{`, the bullets end up too close to each other, so we need to add a bit of letter-spacing for these fields only.`}</p>
    <p>{`Here’s a snippet of CSS that does just that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`input[placeholder*="••••"]::-webkit-input-placeholder {
    letter-spacing: .1em;
}
`}</code></pre>
    <p>{`That selector looks for an input with a placeholder that contains four bullets in a row`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` and applies a bit of letter-spacing to it. There you have it. `}<a parentName="p" {...{
        "href": "http://cl.ly/image/3u0Q0U2H3n3i"
      }}>{`Better looking placeholders`}</a>{`!`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Targetting placeholders with just one bullet would work just as well, but by targetting four bullets we make sure that a placeholder that contains just one bullet (and some text maybe) wouldn’t be affected by this.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      